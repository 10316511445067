import axios from "axios";
import jsCookie from "js-cookie";

import httpConfig from "@/http/config.js";

export const service = axios.create({
  baseURL: httpConfig.requestUrl,
  timeout: 30 * 1000,
  headers: {
    "content-type": "application/json",
  },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (jsCookie.get("token")) {
      config.headers["Authorization"] = jsCookie.get("token");
    } else {
      config.headers["Authorization"] = "";
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response) {
      if (error.response.data["message"]) {
        console.log(error.response.data["message"]);
      }
      console.log(error.response.data);
      return error.response.data;
    } else {
      console.log("response错误拦截");
      return Promise.reject();
    }
  }
);
